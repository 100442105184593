<template>
  <div class="chat-window">
    <div class="chat-title">
      <p>垃圾箱</p>
      <p>{{ total }}</p>
    </div>
    <div class="chat-container">
      <div class="table-list">
        <div class="top-button">
          <el-button round
                     size="small"
                     @click="shiftDelete">彻底删除</el-button>
          <el-button round
                     size="small"
                     @click="handleRestore">还原</el-button>
        </div>
        <el-table
          :data="tableData"
          style="width: 100%"
          @selection-change="handleSelectionChange"
          @row-click="openDetails"
          :row-key="getKey"
          ref="table"
          border
        >
          <el-table-column
            type="selection"
            align="center"
            reserve-selection
            width="40"
          ></el-table-column>
          <el-table-column label="收件人" align="center" prop="receiveMemberMail">
            <template slot-scope="scope">
              <span @click="lookView(scope.row)">
                {{ scope.row.receiveUsername}}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            label="主题"
            align="center"
            width="200px"
            prop="subject"
          >
            <template slot-scope="scope">{{ scope.row.subject }}</template>
          </el-table-column>
          <el-table-column label="时间" align="center" prop="sendTime">
            <template slot-scope="scope">{{ scope.row.sendTime }}</template>
          </el-table-column>
          <!-- <el-table-column label="操作" align="center" prop="status">
            <template slot-scope="scope">
              <el-button type="text" @click="handleTranspond(scope.row)"
                >转发</el-button
              >
              <span style="color: #409eff"> | </span>
              <el-button type="text" @click="lookView(scope.row)"
                >详情</el-button
              >
            </template>
          </el-table-column> -->
        </el-table>
        <!-- <div slot="empty">
            <p style="font-size: 40px">没有邮件</p>
            <p style="font-size: 20px">系统自动清理超过30天垃圾箱邮件</p>
          </div> -->
      </div>
      <div class="submit">
        <div class="submit-button">
          <el-button round size="small" @click="shiftDelete"
            >彻底删除</el-button
          >
          <el-button round size="small" @click="handleRestore">还原</el-button>
        </div>
        <div v-if="tableData.length > 0" class="pagination-container">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            layout="total,prev, pager, next"
            :page-size="listQuery.pageSize"
            :current-page.sync="listQuery.pageNum"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // 邮箱-垃圾箱
  name: "dustbinBox",
  components: {},
  data() {
    return {
      total: 0,
      loginMsg: {},
      listQuery: {
        pageNo: 1,
        pageSize: 10,
      },
      model: {},
      multipleSelection: [],
      tableData: [],
    };
  },
  created() {
    if (localStorage.getItem("loginMsg")) {
      this.loginMsg = JSON.parse(localStorage.getItem("loginMsg"));
    }
    this.getEmailList();
  },
  methods: {
    // clearData() {
    //   if (this.$route.query.isClear) {
    //     this.$confirm(
    //       "您要清空" + this.$route.query.name + "吗？",
    //       "清空" + name,
    //       {
    //         confirmButtonText: "确定",
    //         cancelButtonText: "取消",
    //         center: true,
    //         customClass: "msgBox",
    //       }
    //     ).then(() => {
    //       this.shiftDelete();
    //     });
    //   }
    // },
     // 转发
     handleTranspond(val) {
      this.$router.push({
        path: "sendEmails",
        query: { model: JSON.stringify(val) },
      });
    },
    // 查看邮件详情
    lookView(val) {
      this.$router.push({ path: "./emailDetail", query: { id: val.mailId ,title:'垃圾箱'} });
    },
    // 跳转邮件详情信息
    openDetails(row) {
      // this.$router.push({ path: 'InboxDetailInfo', query: { params: row } })
    },
    // 彻底删除
    shiftDelete() {
      if (this.multipleSelection.length == 0) {
        this.$message({
          message: "请先选择您要彻底删除的邮件",
          type: "warning",
        });
        return;
      }
      let idArr = [];
      this.multipleSelection.forEach((item) => {
        idArr.push(JSON.parse(JSON.stringify(item.mailId)));
      });
      let ids = idArr.join(",");
      console.log(ids);
      this.$api.email.batchDeleteEmail({ ids }).then((res) => {
        this.getEmailList();
        this.$refs.table.clearSelection()
        this.$message({
          message: "彻底删除邮件成功",
          type: "success",
        });
        this.$emit('sendOk')
      });
    },
    // 还原
    handleRestore() {
      if (this.multipleSelection.length == 0) {
        this.$message({
          message: "请先选择您要还原的邮件",
          type: "warning",
        });
        return;
      }
      let idArr = [];
      this.multipleSelection.forEach((item) => {
        idArr.push(JSON.parse(JSON.stringify(item.mailId)));
      });
      let ids = idArr.join(",");
      console.log(ids);
      this.$api.email.batchReturnEmail({ ids }).then((res) => {
        this.getEmailList();
        this.$refs.table.clearSelection()
        this.$message({
          message: "还原邮件成功",
          type: "success",
        });
      });
    },
    // 表格选择
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    getKey(row) {
      return row.id;
    },
    //获取邮件列表
    getEmailList(callBack) {
      let params = this.listQuery;
      params.memberId = this.loginMsg.memberId;
      params.mailType = 2;
      this.$api.email
        .emailList(params)
        .then((res) => {
          console.log("邮箱列表", res);
          this.tableData = res.result.records;
          this.total = res.result.total;
          this.tableData.forEach((item) => {
            this.$set(item, "id", JSON.stringify(item.mailId));
          });
          this.$emit("updateNum", this.total);
          if (callBack) {
            callBack();
          }
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    // 每页页数变化
    handleSizeChange(val) {
      this.listQuery.pageSize = val;
      this.getEmailList();
    },
    // 当前页变化
    handleCurrentChange(val) {
      this.listQuery.pageNo = val;
      this.getEmailList();
    },
  },
};
</script>

<style scoped>
.chat-window {
  min-width: 506px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-left: 1px solid #dcdfe6;
  flex: 1;
  height: 100%;
  position: relative;
}
.chat-window .chat-title {
  display: flex;
  align-items: center;
  height: 60px;
  font-size: 18px;
  color: #313233;
  letter-spacing: 2px;
  padding: 0 20px;
  background: #ffffff;
  box-sizing: border-box;
  border-bottom: 1px solid #dcdfe6;
}
.chat-window .chat-title p:nth-child(2) {
  font-size: 18px;
  color: #7b7d80;
  margin-left: 8px;
}
.chat-window .chat-container {
  min-height: 540px;
  height: 100%;
  width: 100%;
  /* padding-top: 20px; */
  box-sizing: border-box;
  border-bottom: 1px solid #dcdfe6;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* overflow-x: hidden;
  overflow-y: auto; */
  /* position: relative; */
}
.top-button {
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 10px;
}
.top-button button {
  width: 56px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.top-button button:nth-child(1) {
  width: 86px;
}
.submit {
  width: 100%;
  height: 68px;
  padding-left: 10px;
  padding-right: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* position: absolute;
  bottom: 0; */
  border-top: 1px solid #dcdfe6;
}
.submit-button {
  display: flex;
  flex-direction: row;
}
.submit button {
  width: 56px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.submit button:nth-child(1) {
  width: 86px;
}
.table-list >>> .el-table__empty-text {
  width: 100%;
  color: #abaeb3;
  padding: 20px 0;
}
</style>